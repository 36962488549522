import * as React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import { Box } from "@components/box";
import { PageLayout, SEO } from "@components/page-layout";
import {
  Heading,
  Paragraph,
  GrayPhrase,
  Subheading,
} from "@components/typography";
import { SideHeader } from "@containers/header";
import { GatsbyLink } from "@components/gatsby-link";
import { DetailedCompareTable } from "@brainfinance/icash-component-library";
import { TABLE_BOTTOM, TABLE_TOP } from "@containers/detailed-compare-table/table-data";


const VSOtherLendersPage = (props: any) => (
  <PageLayout>
    <SEO title="iCash vs other lenders" />
    <SideHeader
      src={getImage(props.data.file)?.images.fallback?.src || ""}
      srcSet={getImage(props.data.file)?.images.fallback?.srcSet || ""}
      description=""
      buttonOff
    >
      <GrayPhrase>iCash</GrayPhrase> vs other
      <br />
      lenders
    </SideHeader>
    <Box className="px-[2.75rem] sm:px-[22px]">
      <Box className="max-w-[1320px] mx-auto py-[5.5rem] md:py-[2rem] sm:pb-[22px]">
        <Heading>
          <GrayPhrase>Best rates</GrayPhrase> for short term loans
        </Heading>
        <Box className="max-w-[660px]">
          <Box className="my-[1.375rem]">
            <Paragraph>
              <GrayPhrase>Comparison chart.</GrayPhrase>
            </Paragraph>
          </Box>
          <Box className="my-[1.375rem]">
            <Paragraph>
              <GrayPhrase>
                Navigating through the different short-term and payday loan offers
                can get overwhelming. Some lenders follow the payday loan
                regulations and apply a fixed rate on the amount borrowed as the
                cost of borrowing. Some other lenders do not have a license and
                charge annual interests and brokerage fees. Even though they offer
                longer terms, is it really the better option? Trying to figure out
                the best solution for you can be daunting, especially when you
                need to go with the fastest option.
              </GrayPhrase>
            </Paragraph>
          </Box>
          <Box className="mt-[4.25rem] mb-[1.375rem]">
            <Subheading>iCash is fast and offers low-cost loans!</Subheading>
          </Box>
          <Box className="my-[1.375rem]">
            <Paragraph>
              <GrayPhrase>
                Here is an easy-to-decipher comparison chart to help you better
                understand which short term or online loan solution is right for
                you (based on the British Columbia payday loan rates):
              </GrayPhrase>
            </Paragraph>
          </Box>
        </Box>
      </Box>
      <Box className="max-w-[1366px] mx-auto">
        <DetailedCompareTable
          topTableData={TABLE_TOP}
          bottomTableData={TABLE_BOTTOM}
        />
      </Box>
      <Box className="max-w-[1320px] mx-auto pb-[5rem] md:pb-[2.75rem] sm:pb-[22px]">
        <Box className="max-w-[660px]">
          <Box className="mt-[4.25rem] mb-[1.375rem]">
            <Paragraph>
              <GrayPhrase>
                Often, it takes a breakdown of the information, side-by-side, in
                order to be able to make an informed decision. Comparing the terms
                of online short-term loans and payday loans in your province is
                important in order to get a clear understanding before you sign a
                loan contract. Each province has different regulations governing
                the rates for all licensed lenders. If you have any questions
                about the rates in your province, our Customer Experience Advisors
                would be more than happy to assist you.
              </GrayPhrase>
            </Paragraph>
          </Box>
          <Paragraph>
            <GrayPhrase>
              Need help finding out the right amount of your loan?{" "}
              Visit our <GatsbyLink to="#">Budgeting page</GatsbyLink>!
            </GrayPhrase>
          </Paragraph>
        </Box>
      </Box>
    </Box>
  </PageLayout>
);

export default VSOtherLendersPage;

export const pageQuery = graphql`
  query {
    file(relativePath: {eq: "fullscreen-background.jpg"}) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1160)
      }
    }
  }
`