type TableContentType = {
  title: string;
  items: [
    {text: string; subtext?: string;}, 
    {text: string; subtext?: string;}, 
    {text: string; subtext?: string;}
  ];
}[];

export const TABLE_TOP: TableContentType = [
  {
    title: "Terms",
    items: [
      {
        text: "42 day term",
        subtext: "(with the 3 repayment options available)",
      },
      { text: "10 day term" },
      { text: "70 day term with 32% interest" },
    ],
  },
  {
    title: "Principle issued",
    items: [{ text: "$500" }, { text: "$500" }, { text: "$500" }],
  },
  {
    title: "Broker fee",
    items: [{ text: "No fee" }, { text: "No fee" }, { text: "$229.31" }],
  },
  {
    title: "Cost of borrowing (in percentage)",
    items: [
      { text: "15% of the borrowed amount" },
      { text: "15% of the borrowed amount" },
      { text: "32% annual" },
    ],
  },
  {
    title: "Cost of borrowing on a $500 loan",
    items: [{ text: "$75" }, { text: "$75" }, { text: "$30.68" }],
  },
  {
    title: "Total cost of borrowing (interest fees and brokerage fees)",
    items: [{ text: "$75" }, { text: "$75" }, { text: "$259.99" }],
  },
];

export const TABLE_BOTTOM: TableContentType = [
  {
    title: "Pro-rated cost of borrowing per day",
    items: [
      { text: "$1.78 per day" },
      { text: "$7.50 per day" },
      { text: "$3.71 per day" },
    ],
  },
  {
    title: "Annual percentage rate",
    items: [{ text: "130%" }, { text: "548%" }, { text: "271%" }],
  },
  {
    title: "Licensed",
    items: [{ text: "Yes" }, { text: "Not all" }, { text: "No" }],
  },
  {
    title:
      "Offers a cashback program of up to 20% of cost of borrowing in return*",
    items: [{ text: "Yes" }, { text: "No" }, { text: "No" }],
  },
  {
    title: "Paperless",
    items: [{ text: "Yes" }, { text: "No" }, { text: "No" }],
  },
  {
    title: "Long term client benefits status",
    items: [{ text: "Yes" }, { text: "No" }, { text: "No" }],
  },
  {
    title: "e-Transfer for all customer in 2 minutes",
    items: [{ text: "Yes" }, { text: "No" }, { text: "No" }],
  },
];